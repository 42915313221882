<div *ngIf="loadedError; else noError" class="pb-6">
    <div class="tab-pane card">
        <div class="text-center pt-7">
            <h4 class="font-weight-bold">{{ 'web.we_are_sorry' | translate }}</h4>
            <p class="mb-6">{{ 'web.error_data_load' | translate }}</p>
        </div>
    </div>
</div>
<ng-template #noError>
    <div class="container bg-light position-relative app-match-detail-page">
        <ng-container *ngIf="isOptaTournament">
            <app-opta-competition-stats
                [competitionId]="optaCompetition"
                [seasonId]="optaSeason"
            ></app-opta-competition-stats>
            <app-opta-team-ranking [competitionId]="optaCompetition" [seasonId]="optaSeason"></app-opta-team-ranking>
        </ng-container>
    </div>
</ng-template>
<app-lang class="d-none" [mobile]="false" [flags]="false"></app-lang>
