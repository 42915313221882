import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { ComponentModule } from '@components/component.module';


import { WidgetRoutingModule } from './widget-routing.module';
import { WidgetComponent } from './widget.component';

import { SharedModule } from '@/shared/shared.module';


@NgModule({
    declarations: [WidgetComponent],
    imports: [CommonModule, SharedModule, WidgetRoutingModule, ComponentModule],
})
export class WidgetModule { }
