/**
 *  Widget component
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2024 livescore
 */

import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { PageMixin } from 'src/app/services/mixins/page.mixin';
import { BaseComponent } from '@pages/base.component';
import { PageService } from '@services/page.service';
import { InfoService } from '@services/info.service';
import { ClientService } from '@services/client.service';
import { LangService } from '@services/lang.service';
import { UtilsService } from '@services/utils.service';

@Component({
    selector: 'app-widget',
    templateUrl: './widget.component.html',

})
export class WidgetComponent extends PageMixin(BaseComponent) implements OnInit {
    public optaSeason: string | null = null;
    public optaCompetition: string | null = null;

    public constructor(
        protected page: PageService,
        protected title: Title,
        protected meta: Meta,
        protected route: Router,
        protected translate: TranslateService,
        protected client: ClientService,
        protected lang: LangService,
        protected info: InfoService,
        private router: ActivatedRoute,

    ) {
        super(page, title, meta, route, info);
    }

    /**
     * Load data from server
     */
    public ngOnInit(): void {
        super.ngOnInit();

        $('body').removeClass('loader-active');

        this.router.paramMap.subscribe((param: ParamMap) => {
            if (param.has('optaCompetition') && param.has('optaSeason')) {
                this.optaCompetition = param.get('optaCompetition');
                this.optaSeason = param.get('optaSeason');
            } else {
                this.error();
            }
        });

        UtilsService.addClass(document.getElementsByTagName('body')[0], 'match-widget--mode');
    }

    get isOptaTournament(): boolean {
        return !!(this.optaCompetition && this.optaSeason);
    }


    /**
     * Create page title and meta
     * @return Promise<any>
     */
    private createTitle(): any {
        this.translate.get('web.title_match').subscribe((): void => {
            const trans = this.translate.instant('web.title_widgets');

            const desc = this.translate.instant('web.description_widgets');
            const client = this.client.getVName();
            this.setTitle(
                {
                    title: `${trans} | ${client}`,
                    description: `${desc}`,
                },
                true,
            );
        });
    }
}
